import { UPDATE_CACHE_BREAK_TOKEN } from 'Reducers/app/cacheBreakReducer'

export const saveCacheBreakToken = token => ({
  type: UPDATE_CACHE_BREAK_TOKEN,
  token,
})

export const updateCacheBreakToken = () => dispatch => {
  const token = Date.now().toString()
  localStorage.setItem('cacheBreakToken', token)

  dispatch(saveCacheBreakToken(token))
}
