export const UPDATE_CACHE_BREAK_TOKEN = 'UPDATE_CACHE_BREAK_TOKEN'

const initialState = {
  token: Date.now().toString(),
}

export default function cacheBreakReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CACHE_BREAK_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    default:
      return state
  }
}
