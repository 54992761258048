import {
  ARTWORK_COMPARISONS,
  ASSOCIATED_MERCHANDIZING_SCANS,
  AUDITS,
  AUDIT_ACTIONS,
  AUDIT_ARTWORKS,
  AUDIT_EXCEPTIONS,
  AUDIT_PRICES,
  BRANDS,
  CHANNELS,
  CLIENT,
  CLIENTS,
  CLIENT_DASHBOARD,
  CLIENT_META,
  CLIENT_COMPETITORS,
  CLIENT_SALES_SUMMARY,
  COMPETITORS,
  DATA,
  FILTERS,
  LINKS,
  LOCATION_CANDIDATES,
  MARKET_SHARES,
  MATCHES,
  MERCHANDIZING,
  META,
  OPTIMIZATION_SCORES,
  PAGES,
  PLATFORMS,
  PLATFORM_TITLES,
  PRICES,
  PUBLISHERS,
  SCANS,
  SCAN_DOWNLOADS,
  SECTIONS,
  SPOTS,
  STUDIOS,
  SUMMARY,
  TABLEAU_TOKEN,
  TAGGINGS,
  TAGS,
  TERRITORIES,
  TITLES,
  TITLES_FOR_CORRECTION,
  TITLE_AUDITS,
  MERCHANDIZING_SCAN_STATISTICS,
} from 'Reducers/app/utils'
import { createSelector } from 'reselect'

export const getPlatformTitles = state => state.app.getIn([PLATFORM_TITLES, DATA])
export const getPlatformTitlesMeta = state => state.app.getIn([PLATFORM_TITLES, META])
export const getPlatformTitlesLinks = state => state.app.getIn([PLATFORM_TITLES, LINKS])
export const getClients = state => state.app.getIn([CLIENTS])
export const getClient = state => state.app.getIn([CLIENT])
export const getClientMeta = state => state.app.getIn([CLIENT_META])
export const getClientCompetitors = state => state.app.getIn([CLIENT_COMPETITORS])
export const getScans = state => state.app.getIn([SCANS, DATA])
export const getScanFilters = state => state.app.getIn([SCANS, FILTERS])
export const getSpotFilters = state => state.app.getIn([MERCHANDIZING, SPOTS, FILTERS])
export const getPagesMeta = state => state.app.getIn([MERCHANDIZING, PAGES, META])
export const getScanSummary = state => state.app.getIn([SCANS, SUMMARY])
export const getAudits = state => state.app.getIn([AUDITS])
export const getTitleAudits = state => state.app.getIn([TITLE_AUDITS, DATA])
export const getTitleAuditsLinks = state => state.app.getIn([TITLE_AUDITS, LINKS])
export const getTitleAuditsMeta = state => state.app.getIn([TITLE_AUDITS, META])
export const getTitles = state => state.app.getIn([TITLES, DATA])
export const getTitlesMeta = state => state.app.getIn([TITLES, META])
export const getTags = state => state.app.getIn([TAGS])
export const getTaggings = state => state.app.getIn([TAGGINGS])
export const getScanDownloads = state => state.app.getIn([SCAN_DOWNLOADS])
export const getStudios = state => state.app.getIn([STUDIOS])
export const getTitlesForCorrection = state => state.app.getIn([TITLES_FOR_CORRECTION, DATA])
export const getTitlesForCorrectionMeta = state => state.app.getIn([TITLES_FOR_CORRECTION, META])
export const getMatchesMeta = state => state.app.getIn([MERCHANDIZING, MATCHES, META])
export const getTitlesLinks = state => state.app.getIn([TITLES, LINKS])
export const getTitleFilters = state => state.app.getIn([TITLES, FILTERS])
export const getCompetitorFilters = state => state.app.getIn([COMPETITORS, FILTERS])
export const getPlatforms = state => state.app.getIn([PLATFORMS])
export const getTerritories = state => state.app.getIn([TERRITORIES])
export const getAuditArtworks = state => state.app.getIn([AUDIT_ARTWORKS])
export const getAuditPrices = state => state.app.getIn([AUDIT_PRICES])
export const getChannels = state => state.app.getIn([CHANNELS])
export const getAuditActions = state => state.app.getIn([AUDIT_ACTIONS, DATA])
export const getAuditActionsLinks = state => state.app.getIn([AUDIT_ACTIONS, LINKS])
export const getAuditActionsMeta = state => state.app.getIn([AUDIT_ACTIONS, META])
export const getAuditExceptions = state => state.app.getIn([AUDIT_EXCEPTIONS, DATA])
export const getAuditExceptionsLinks = state => state.app.getIn([AUDIT_EXCEPTIONS, LINKS])
export const getAuditExceptionsMeta = state => state.app.getIn([AUDIT_EXCEPTIONS, META])
export const getPrices = state => state.app.getIn([PRICES])
export const getUI = state => state.ui
export const getCSRF = state => state._csrf
export const getOptimizationScores = state => state.app.getIn([OPTIMIZATION_SCORES, TITLE_AUDITS])
export const getBrandOptimizationScores = state => state.app.getIn([OPTIMIZATION_SCORES, BRANDS])
export const getRootPath = state => state.ui.get('rootPath')
export const getClientSalesSummary = state => state.app.getIn([CLIENT_SALES_SUMMARY])
export const getMarketShare = state => state.app.getIn([MARKET_SHARES, DATA])
export const getMarketShareFilters = state => state.app.getIn([MARKET_SHARES, FILTERS])
export const getMarketShareMeta = state => state.app.getIn([MARKET_SHARES, META])
export const getClientDashboard = state => state.app.getIn([CLIENT_DASHBOARD])
export const getTableauToken = state => state.app.getIn([TABLEAU_TOKEN])
export const getPublishers = state => state.app.getIn([PUBLISHERS])
export const getArtworkComparisons = state => state.app.getIn([ARTWORK_COMPARISONS])
export const getLocationCandidates = state => state.app.getIn([LOCATION_CANDIDATES, DATA])
export const getLocationCandidatesMeta = state => state.app.getIn([LOCATION_CANDIDATES, META])
export const getMerchandizingScans = state => state.app.getIn([MERCHANDIZING, SCANS, DATA])
export const getMerchandizingPages = state => state.app.getIn([MERCHANDIZING, PAGES, DATA])
export const getMerchandizingSections = state => state.app.getIn([MERCHANDIZING, SECTIONS, DATA])
export const getMerchandizingSectionsLinks = state => state.app.getIn([MERCHANDIZING, SECTIONS, LINKS])
export const getMerchandizingSpots = state => state.app.getIn([MERCHANDIZING, SPOTS, DATA])
export const getMerchandizingSpotsMeta = state => state.app.getIn([MERCHANDIZING, SPOTS, META])
export const getMerchandizingSpotsLinks = state => state.app.getIn([MERCHANDIZING, SPOTS, LINKS])
export const getMerchandizingMatches = state => state.app.getIn([MERCHANDIZING, MATCHES, DATA])
export const getAssociatedMerchandizingScans = state => state.app.getIn([ASSOCIATED_MERCHANDIZING_SCANS])
export const getMerchandizingPlatformTitles = state => state.app.getIn([MERCHANDIZING, PLATFORM_TITLES, DATA])
export const getMerchandizingScanDownloads = state => state.app.getIn([MERCHANDIZING, SCAN_DOWNLOADS])
export const getSectionsMeta = state => state.app.getIn([MERCHANDIZING, SECTIONS, META])
export const getSpotsMeta = state => state.app.getIn([MERCHANDIZING, SPOTS, META])
export const getScansMeta = state => state.app.getIn([SCANS, META])
export const getMerchandizingScanStatistics = state => state.app.getIn([MERCHANDIZING_SCAN_STATISTICS])

export const makeGetSelectedMerchandizingSection = () =>
  createSelector(getMerchandizingSections, section => section.find(m => m.selected))

export const getMerchandizingScanDownloadsInProgress = createSelector(
  state => getMerchandizingScanDownloads(state),
  inProgress => inProgress && inProgress.filter(s => s.status !== 'error' && s.status !== 'complete')
)

export const getSelectedAssociatedMerchandizingScans = createSelector(
  state => getAssociatedMerchandizingScans(state),
  amsScans => amsScans.filter(amsScan => amsScan.selected)
)

export const getSelectedMerchandizingSection = makeGetSelectedMerchandizingSection()

export const getSelectedPlatformTitle = createSelector(
  state => getPlatformTitles(state),
  platformTitles => platformTitles.find(platformTitle => platformTitle.selected)
)

export const makeGetPlatformTitleById = () =>
  createSelector(
    state => getPlatformTitles(state),
    (_state, params) => params.id,
    (platformTitles, id) => platformTitles.get(id)
  )

export const getPlatformTitleById = makeGetPlatformTitleById()

export const makeGetAuditActionById = () =>
  createSelector(
    state => getAuditActions(state),
    (_state, params) => params.id,
    (auditActions, id) => auditActions.get(id)
  )

export const getAuditActionById = makeGetAuditActionById()

export const makeGetTitleById = () =>
  createSelector(
    state => getTitles(state),
    (_state, params) => params.id,
    (titles, id) => titles.get(id)
  )

export const getTitleById = makeGetTitleById()

export const makeGetTitleForCorrectionById = () =>
  createSelector(
    state => getTitlesForCorrection(state),
    (_state, params) => params.id,
    (titles, id) => titles.get(id)
  )

export const getTitleForCorrectionById = makeGetTitleForCorrectionById()

export const makeGetScanById = () =>
  createSelector(
    state => getScans(state),
    (_state, params) => params.id,
    (scans, id) => scans.get(id)
  )

export const makeGetMerchandizingScanById = () =>
  createSelector(
    state => getMerchandizingScans(state),
    (_state, params) => params.id,
    (scans, id) => scans.get(id)
  )
export const getMerchandizingScanById = makeGetMerchandizingScanById()

export const makeGetMerchandizingPageById = () =>
  createSelector(
    state => getMerchandizingPages(state),
    (_state, params) => params.id,
    (pages, id) => pages.get(id)
  )
export const getMerchandizingPageById = makeGetMerchandizingPageById()

export const getScanById = makeGetScanById()
export const getSelectedScan = createSelector(
  state => getScans(state),
  scans => scans.find(scan => scan.selected)
)

export const getSelectedAudit = createSelector(
  state => getAudits(state),
  audits => audits.find(audit => audit.selected)
)

export const getAuditsForScan = createSelector(
  state => getAudits(state),
  (_state, props) => props.scan,
  (audits, scan) => audits.filter(a => a.scanId.toString() === scan.id.toString())
)

export const getPlatformTitleForAudit = createSelector(
  state => getPlatformTitles(state),
  (_state, props) => props.audit,
  (platformTitles, audit) => platformTitles.get(audit.platformTitleId.toString())
)

export const getPlatformTitlesForScan = createSelector(
  state => state,
  (state, props) => getAuditsForScan(state, props),
  (state, audits) => audits.map(a => getPlatformTitleForAudit(state, { audit: a }))
)

export const getTitleAuditsForScan = createSelector(
  state => getTitleAudits(state),
  (_state, props) => props.scan,
  (titleAudits, scan) => titleAudits.filter(a => scan.id && a.scanId && a.scanId.toString() === scan.id.toString())
)

export const getTitlesForScan = createSelector(
  state => state,
  (state, props) => getTitleAuditsForScan(state, props),
  (state, titleAudits) => titleAudits.map(titleAudit => titleAudit.getTitle(state))
)

export const getTitleAuditById = createSelector(
  state => getTitleAudits(state),
  (_state, props) => props.id,
  (titleAudits, id) => titleAudits.get(id)
)

export const getPlatformById = createSelector(
  state => getPlatforms(state),
  (_state, props) => props.id,
  (platforms, id) => platforms.get(id)
)

export const getPublisherById = createSelector(
  state => getPublishers(state),
  (_state, props) => props.id,
  (publishers, id) => publishers.get(id)
)

export const getTerritoryById = createSelector(
  state => getTerritories(state),
  (_state, props) => props.id,
  (territories, id) => territories.get(id)
)

export const getAuditActionsAsList = createSelector(
  state => getAuditActions(state),
  auditActions => auditActions.valueSeq()
)

export const makeGetAuditActionForRowIndex = () => {
  return createSelector(
    state => getAuditActionsAsList(state),
    (_state, props) => props.rowIndex,
    (auditActionList, rowIndex) => {
      const action = auditActionList.get(rowIndex)
      return Array.isArray(action) ? action[1] : action
    }
  )
}

export const getSelectedTerritories = createSelector(
  state => getTerritories(state),
  territories => territories.filter(t => t.selected)
)

export const getScanDownloadsInProgress = createSelector(
  state => getScanDownloads(state),
  inProgress => inProgress && inProgress.filter(s => s.status !== 'error' && s.status !== 'complete')
)

export const getSelectedPlatforms = createSelector(
  state => getPlatforms(state),
  state => getSelectedTerritories(state),
  (platforms, territories) => {
    const territoryIds = territories
      .valueSeq()
      .map(t => t.id)
      .toArray()

    return platforms
      .filter(p => p.selected && territoryIds.includes(p.territoryId))
      .sort((a, b) => a.name.localeCompare(b.name))
  }
)

export const getSelectedTags = createSelector(
  state => getTags(state),
  tags => tags && tags.filter(t => t.selected)
)

export const makeGetTagById = () =>
  createSelector(
    state => getTags(state),
    (_state, params) => params.id,
    (tags, id) => tags.get(id)
  )

export const getTagById = makeGetTagById()

export const getSelectedPlatformsWithShowFlags = createSelector(
  state => getSelectedPlatforms(state),
  platforms => {
    let lastTerritory = ''
    return platforms.toList().map((platform, _index) => {
      if (platform.territoryId !== lastTerritory) {
        lastTerritory = platform.territoryId
        return {
          platform,
          showFlag: true,
        }
      } else {
        lastTerritory = platform.territoryId
        return {
          platform,
          showFlag: false,
        }
      }
    })
  }
)

export const makeGetActionCountForTitleAudit = () =>
  createSelector(
    state => state,
    (_state, props) => props.titleAudit,
    (state, titleAudit) => titleAudit.getActionCount(state)
  )

export const makeGetMerchandizingSpotsById = () =>
  createSelector(
    state => getMerchandizingSpots(state),
    (_state, params) => params.id,
    (spots, id) => {
      return spots.find(spot => spot.get('id') === id)
    }
  )

export const getGetMerchandizingSpotsById = makeGetMerchandizingSpotsById()

export const makeGetMerchandizingSectionBySpotID = () =>
  createSelector(
    state => getMerchandizingSections(state),
    (_state, params) => params.spotID,
    (sections, spotID) => {
      let result = null
      sections.some(section => {
        if (section.spotsIds && section.spotsIds.find(id => id === spotID)) {
          result = section
          return true
        } else {
          return false
        }
      })
      return result
    }
  )
export const getGetMerchandizingSectionBySpotID = makeGetMerchandizingSectionBySpotID()

export const isMarketShareLoading = createSelector(
  state => getMarketShareMeta(state),
  meta => meta.get('loading')
)

export const isTitleAuditLoading = createSelector(
  state => getTitleAuditsMeta(state),
  meta => meta.get('loading')
)

export const getHasMoreTitleAuditsToLoad = createSelector(
  state => getTitleAuditsMeta(state),
  meta => meta.get('hasMoreToLoad')
)

export const getHasMoreDeliveredScansToLoad = createSelector(
  state => getScansMeta(state),
  meta => meta.get('hasMoreDeliveredScansToLoad')
)

export const isSpotsLoading = createSelector(
  state => getSpotFilters(state),
  filter => filter.get('spotsLoading')
)

export const areMerchandisingMatchesLoading = createSelector(
  state => getMatchesMeta(state),
  meta => meta.loading
)

export const areTitlesForCorrectionLoading = createSelector(
  state => getTitlesForCorrectionMeta(state),
  meta => meta.get('isLoading')
)

export const arePlatformTitlesUpdating = createSelector(
  state => getPlatformTitlesMeta(state),
  meta => meta.updating
)

export const getScrollingSpotId = createSelector(
  state => getPagesMeta(state),
  meta => meta.get('scrollingSpotId')
)

export const loadingPageData = createSelector(
  state => getPagesMeta(state),
  meta => meta.get('loadingPageData')
)

export const getTitleAuditsPage = createSelector(
  state => getTitleAuditsMeta(state),
  meta => meta.get('page')
)

export const getAuditActionsPage = createSelector(
  state => getAuditActionsMeta(state),
  meta => meta.get('page')
)

export const getTitlesPage = createSelector(
  state => getTitlesMeta(state),
  meta => meta.get('page')
)

export const getMerchandizingSectionsPage = createSelector(
  state => getSectionsMeta(state),
  meta => meta.get('page')
)

export const getHasMoreTitlesToLoad = createSelector(
  state => getTitlesMeta(state),
  meta => meta.get('hasMoreToLoad')
)

export const getTitlesLoading = createSelector(
  state => getTitlesMeta(state),
  meta => meta.get('isLoading')
)

export const getHasMoreSpotsToLoad = createSelector(
  state => getSpotsMeta(state),
  meta => meta.get('hasMoreToLoad')
)

export const getSelectedClientCompetitors = createSelector(
  state => getClientCompetitors(state),
  clientCompetitors => clientCompetitors.filter(clientCompetitor => clientCompetitor.selected)
)
