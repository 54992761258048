import TagRecord from 'Records/tagRecord'
import { Map, OrderedMap } from 'immutable'

import { readEndpoint } from 'Actions/apiActions'
import { getClient, getTagById } from 'Selectors/index'
import queryString from 'query-string'
import { reportError } from '../utils/errors'
import { apiRequest, handleErrorResponse } from './apiActions'
import { reloadScan } from './scanActions'
import { normalizeAndLoadTaggings } from './taggingActions'
import { showErrorMessageToast } from './uiActions'
import { updateCacheBreakToken } from './cacheBreakActions'

export const loadTags = tags => ({
  type: 'LOAD_TAGS',
  tags,
})

export const taggRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new TagRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: new Map(item.relationships),
    })
  )
}

export const retrieveTags = clientId => dispatch => {
  const limit = 250
  return readEndpoint(`tags?filter[clientId]=${clientId}&page[limit]=${limit}`).then(response => {
    dispatch(normalizeAndLoadTags(response.tags))
  })
}

export const addTag = (name, title, territoryIds) => dispatch => {
  return dispatch(
    apiRequest('tags', 'POST', {
      attributes: { name },
    })
  )
    .then(response => {
      if (response.type === 'error') throw new Error(response.message.detail)

      const { tag } = response
      dispatch(normalizeAndLoadTags([tag]))
      dispatch(updateCacheBreakToken())
      return dispatch(addTagging(tag.id, title, territoryIds))
    })
    .catch(error => {
      reportError(error)

      dispatch(showErrorMessageToast(error.message))
      return Promise.reject()
    })
}

export const normalizeAndLoadTags = data => dispatch => {
  const { tagIds } = queryString.parse(window.location.search)

  const tags = data.reduce((memo, item) => {
    if (tagIds !== undefined) {
      const selectedTagIds = tagIds.split(',')

      item.selected = selectedTagIds.includes(item.id.toString())
    }
    return memo.set(item.id, new TagRecord({ ...item }))
  }, new OrderedMap())

  dispatch(loadTags(tags))
}

export const addTagging = (tagId, title, territoryIds) => (dispatch, getState) => {
  const platformTitles = title.platformTitlesForGivenTerritories(getState(), territoryIds)

  return dispatch(
    apiRequest(`tags/${tagId}/taggings/bulk_create`, 'POST', { territory_ids: territoryIds, title_id: title.id })
  )
    .then(res => {
      if (res.type === 'error') throw new Error(res.message.detail)

      const { taggings } = res
      dispatch(normalizeAndLoadTaggings(taggings))
      taggings.forEach(tagging => {
        const pt = platformTitles.find(p => p.id === tagging.taggableId)
        if (!pt) return

        dispatch({
          type: 'UPDATE_PLATFORM_TITLE',
          platformTitleId: tagging.taggableId,
          data: {
            ...pt.toJS(),
            taggingsIds: [...pt.taggingsIds, tagging.id],
          },
        })
      })

      return Promise.resolve()
    })
    .catch(error => {
      reportError(error)

      dispatch(showErrorMessageToast('There was a problem when creating the tag. Our team has been notified.'))
      return Promise.reject()
    })
}

export const deleteTag = tagId => dispatch => {
  dispatch(apiRequest(`tags/${tagId}`, 'DELETE')).then(() => {
    dispatch(deleteTagFromState(tagId))
    dispatch(updateCacheBreakToken())
    dispatch(reloadScan())
  })
}

export const unlinkTag = tagId => (dispatch, getState) => {
  const state = getState()
  const tag = getTagById(state, { id: tagId })
  const client = getClient(state)
  const url = `tags/${tag.id}/unlink_tag?client_id=${client.id}`

  return dispatch(apiRequest(url, 'DELETE')).then(response => {
    const hasError = dispatch(
      handleErrorResponse(response, 'There was a problem when deleting the tag. Our team has been notified.', true)
    )
    if (hasError) return

    dispatch(deleteTagFromState(tagId))
    dispatch(updateCacheBreakToken())
    dispatch(reloadScan())
  })
}

export const deleteTagFromState = tagId => ({
  type: 'DELETE_TAG',
  tagId,
})

export const toggleTag = tags => dispatch => {
  dispatch({
    type: 'SELECT_TAGS',
    tags,
  })
  dispatch(reloadScan())
}

export const clearSelectedTags = () => ({
  type: 'CLEAR_SELECTED_TAGS',
})
