import { combineReducers } from 'redux'
import { default as csrfReducer } from 'redux-csrf'
import appReducer from './app'
import uiReducer from './uiReducer'
import cacheBreakReducer from './app/cacheBreakReducer'

const rootReducer = combineReducers({
  app: appReducer,
  ui: uiReducer,
  _csrf: csrfReducer,
  cacheBreak: cacheBreakReducer,
})

export default rootReducer
